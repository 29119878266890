import Home from "./wrappers/Home";
import "./app.css";
import Testimonials from "./wrappers/Testimonials";
import Projects from "./wrappers/Projects";
import Skills from "./wrappers/Skills";
import Contacts from "./wrappers/Contacts";
function App() {
  return (
    <>
      <Home />
      <Testimonials />
      <Projects />
      <Skills />
      <Contacts />
    </>
  );
}

export default App;
