import React from "react";
import Swal from "sweetalert2";
import styles from "./project.module.css";
const Project = (props) => {
  const unvailableLinkHandler = () => {
    Swal.fire({
      position: "bottom",
      icon: "error",
      title: "Je ne peux pas vous donner un accès direct à ce projet.",
      showConfirmButton: false,
      timer: 5000,
    });
  };
  return (
    <div className="card mb-3 shadow">
      <div className="card-body">
        {props.url ? (
          <a href={props.url} rel="noreferrer" target="_blank">
            <img
              className={`${styles.img} shadow rounded mb-3`}
              src={props.imgUrl}
              alt="Project"
            />
          </a>
        ) : (
          <>
            <img
              className={`${styles.img} shadow rounded mb-3`}
              src={props.imgUrl}
              onClick={unvailableLinkHandler}
              alt="Project"
            />
          </>
        )}

        <h4 className="card-title">{props.name}</h4>
        <hr />
        <h5 className="card-subtitle mb-2 text-body-secondary">
          {props.shortDesc}
        </h5>
        {/* <p className="card-text"></p> */}
      </div>
    </div>
  );
};
export default Project;
