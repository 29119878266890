import React from "react";
import styles from "./projects.module.css";
import smooove from "../assets/smooove.jpg";
import agileScolarite from "../assets/agileScolarite.png";
import leGrandSaut from "../assets/leGrandSaut.jpg";
import kickla from "../assets/kickla.png";
import guinuty from "../assets/guinuty.png";
import cercle from "../assets/cercle.jpg";
import miitsuv from "../assets/mittsuv.jpg";
import hipeKids from "../assets/hipekids.jpg";
import wakilare from "../assets/wakilare.jpg";
import apprendreMieux from "../assets/apprendreMieux.jpg";

import Project from "../components/Project";
import wavesDivider from "../assets/wavesOpacity.svg";

const projects = [
  {
    id: "p0",
    url: "https://guinuty.com/",
    imgUrl: guinuty,
    name: "Guinuty",
    shortDesc:
      "Développement et maintenance d'une plateforme de crowdfunding robuste en JavaScript.",
  },
  {
    id: "p1",
    url: "https://mercibobby.com/test-orientation",
    imgUrl: leGrandSaut,
    name: "Le grand saut",
    shortDesc:
      "Développement d'une vidéo interactive d'orientation et de reconversion en JavaScript. Une exclus Merci Bobby.",
  },
  {
    id: "p2",
    url: false,
    imgUrl: agileScolarite,
    name: "Agile Scolarité",
    shortDesc:
      "Développement d'une application web de gestion comptable d'etablissement d'enseignement en JavaScript.",
  },
  {
    id: "p3",
    url: "https://smooove.video/",
    imgUrl: smooove,
    name: "Smooove Vidéo",
    shortDesc:
      "Développement front-end et design UI/UX pour l'application Smooove.",
  },
  {
    id: "p4",
    url: false,
    imgUrl: kickla,
    name: "Kickla",
    shortDesc:
      "Développement d'une application web de gestion d'absence en JavaScript.",
  },
  {
    id: "p5",
    url: "https://cercle-festival.com/fr/accueil-2/#festivalfr",
    imgUrl: cercle,
    name: "Animation de texte",
    shortDesc:
      "Création d'une animation de texte sur-mesure en JavaScript sur WP.",
  },
  {
    id: "p6",
    url: "https://miitsuv.co/",
    imgUrl: miitsuv,
    name: "MiitSuv",
    shortDesc:
      "Site vitrine pour une agence de location de site web et referencement.",
  },
  {
    id: "p7",
    url: "https://hipekids.com/",
    imgUrl: hipeKids,
    name: "HipeKids",
    shortDesc:
      "Refonte UI/UX d'un site e-learning d'anglais pour les enfants et leurs parents.",
  },
  {
    id: "p8",
    url: "https://wakilare.com/",
    imgUrl: wakilare,
    name: "Wakilare",
    shortDesc: "Site vitrine et e-commerce multilingue pour l'ONG Wakilaré.",
  },
  {
    id: "p9",
    url: "https://apprendremieux.fr/wp-content/uploads/2020/05/Apprendre-mieux.mp4",
    imgUrl: apprendreMieux,
    name: "Apprendre mieux",
    shortDesc: "Une vidéo de présentation whiteboard pour un site e-learning.",
  },
];
const Projects = () => {
  return (
    <>
      <div id="projects" className={styles.projects}>
        <div className="container pt-5 pb-5">
          <div className="text-center text-white">
            <h3>PROJETS</h3>
            <small>Liste non exhaustive</small>
          </div>
          <div className="row">
            {projects.map((item) => {
              return (
                <div key={item.id} className="col-lg-4 col-md-6">
                  <Project
                    id={item.id}
                    url={item.url}
                    imgUrl={item.imgUrl}
                    name={item.name}
                    shortDesc={item.shortDesc}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <img className="divider" src={wavesDivider} alt="Divider" />
    </>
  );
};

export default Projects;
