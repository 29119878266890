import React from "react";
import wavesDivider from "../assets/wavesOpacity.svg";
import Testimonial from "../components/Testimonial";
import rodolphe from "../assets/ro.jpg";
import nadine from "../assets/nadine.png";
import henri from "../assets/henri.jpg";
import maigret from "../assets/gmts-immo.jpg";
import mehdi from "../assets/mehdi.jpg";
import sergio from "../assets/sergio.jpg";
const testimonials = [
  {
    imgUrl: rodolphe,
    name: "Rodolphe Oppenheimer",
    position: "Psychothérapeute et psychanalyste",
    text: "Prestataire génial, patient, compréhensif, sympathique. Je recommande vivement pour vos projets de creation d'application web sur mesure.",
  },
  {
    imgUrl: nadine,
    name: "Nadine Gondamovo",
    position: "CEO, Apprendre Mieux",
    text: "Salia Camara a réalisé une vidéo pour mon site en remote. Il a été à l'écoute, réactif, et soucieux de respecter mes directives. Je le recommande !",
  },
  {
    imgUrl: henri,
    name: "Henri Matona",
    position: "Founder, PRUDENSIA",
    text: "Projet livré en temps et en heure conformément aux spécifications. Fournisseur disponible à toute heure, compétent, force de proposition et productif en full remote.",
  },
  {
    imgUrl: maigret,
    name: "Maigret Gregori",
    position: "CEO, Operation Cashflow",
    text: "Site Web impeccablement réalisé. Cahier des charges respecté pour un projet de developpement full stack. Je suis à 100% Satisfait et je recommande vivement pour vos projet web !",
  },
  {
    imgUrl: mehdi,
    name: "El Mehdi Benrahhalate",
    position: "CTO | Directeur Technique, HiPe Kids",
    text: "Prestataire UX professionnel ! Salia à su rapidement comprendre les faiblesses de notre site internet pour nous proposer des solutions pertinentes en full remote. Nous avons appréciés ses conseils et sa stratégie pour notre site internet sous wordpress.",
  },
  {
    imgUrl: sergio,
    name: "Sergio Portatadino",
    position:
      "Power Africa Regional Advisor (West Africa) presso Tony Blair Institute for Global Change",
    text: "Monsieur Camara a réalisé le site internet pour mon ONG Wakilaré, il l'a fait rapidement et selon nos indications. L'équipe est satisfaite du travail et heureuse de recommander Mr. Camara a' d'autres clients potentiels.",
  },
];
const Testimonials = () => {
  return (
    <>
      <div id="testimonials" className="container text-center p-5">
        <h3 className="darkBlue">TÉMOIGNAGES</h3>
        <p className="fst-italic fw-bold">
          La meilleure publicité est faite par un client satisfait.
        </p>
        <p>
          Découvrez quelques témoignages de clients, partenaires et
          collaborateurs.
        </p>
        <div className="row">
          {testimonials.map((item, index) => {
            return (
              <div key={index} className="col-lg-6">
                <Testimonial
                  imgUrl={item.imgUrl}
                  name={item.name}
                  position={item.position}
                  text={item.text}
                />
              </div>
            );
          })}
        </div>
        <p className="darkBlue fw-bold">
          Cultiver la confiance et atteindre la satisfaction de mes clients,
          partenaires et collaborateurs est un objectif primordial.
        </p>
        <a href="https://www.codeur.com/-saliacra" title="Saliacra">
          <img
            src="https://api.codeur.com/widgets/badge.svg?k=FO-5SSy8TyRkUR8V"
            title="Saliacra — Codeur.com"
            alt="Salia Camara Codeur.com API Badge"
          />
        </a>
      </div>
      <img
        className="divider rotated dividerTopRelative"
        src={wavesDivider}
        alt="Divider"
      />
    </>
  );
};

export default Testimonials;
