import React from "react";
import styles from "./testimonial.module.css";
const Testimonial = (props) => {
  return (
    <div className="card mb-3 shadow">
      <div className="card-body">
        <img
          className={`${styles.img} shadow mb-3`}
          src={props.imgUrl}
          alt="Customer"
        />
        <h4 className="card-title">{props.name}</h4>
        <h5 className="card-subtitle mb-2 text-body-secondary">
          {props.position}
        </h5>
        <p className="card-text">{props.text}</p>
      </div>
    </div>
  );
};

export default Testimonial;
