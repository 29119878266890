import React from "react";
import styles from "./contacts.module.css";
const Contacts = () => {
  return (
    <div id="contacts" className={styles.contacts}>
      <div className="container text-center p-5">
        <h3>CONTACTS</h3>
        <p>
          Veuillez me contacter pour discuter de votre projet, de vos besoins et
          obtenir un devis.
        </p>
        <div className="row">
          <div className="col-lg-4">
            <div className="card mb-3 shadow">
              <div className="card-body fw-bold">
                <a href="mailto:ceo@studified.xyz">
                  <i className="bi bi-envelope-at contactIcon"></i>
                  <br />
                  ceo@studified.xyz
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card mb-3 shadow">
              <div className="card-body fw-bold">
                <a
                  href="https://wa.me/message/C2X42NKX4GC2I1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-whatsapp contactIcon"></i>
                  <br />
                  +1 (914) 520-6008
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card mb-3 shadow">
              <div className="card-body fw-bold">
                <a
                  href="https://www.linkedin.com/in/saliacamara/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-linkedin contactIcon"></i>
                  <br />
                  Salia Camara
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
