import React from "react";
import wavesDivider from "../assets/wavesOpacity.svg";
const skills = [
  "MongoDB",
  "Express",
  "React",
  "Node",
  "HTML",
  "CSS/CSS Module",
  "Bootstrap",
  "Management",
  "Git",
  "NPM",
  "REST API",
  "NoSqlDB",
  "UI/UX",
  "React Context",
  "Redux",
  "TypeScript",
  "Jest",
  "JWT",
  "Heroku",
  "Cpanel",
];
const Skills = () => {
  return (
    <>
      <div id="skills">
        <div className="container text-center p-5">
          <h3 className="darkBlue">COMPETENCES</h3>
          <small>Liste non exhaustive</small>
          <div className="row">
            {skills.map((skill, index) => (
              <div key={`s${index}`} className="col-lg-3 col-md-6">
                <div className="card mb-3 shadow">
                  <div className="card-body fw-bold">{skill}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <img
        className="divider rotated dividerTopRelative"
        src={wavesDivider}
        alt="Divider"
      />
    </>
  );
};

export default Skills;
