import React from "react";
import Navbar from "../components/NavBar";
import profil from "../assets/profil.jpg";
import styles from "./home.module.css";
import wavesDivider from "../assets/wavesOpacity.svg";
const Home = (props) => {
  return (
    <>
      <div id="home" className={`${styles.home}`}>
        <Navbar />
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h1>Camara Salia</h1>
              <h2>Full Stack Developer</h2>
              <p>
                Bonjour, bienvenue sur mon portfolio, je m'appelle Camara Salia,
                je suis un développeur full stack et freelance actuellement basé
                à Conakry, République de Guinée. <br />
                Vous trouverez ici des présentations de quelques projets
                récents, des témoignages de clients, de partenaires et de
                collaborateurs, ainsi que mes informations de contact.
              </p>
              <a
                href="mailto:ceo@studified.xyz"
                className="btn btn-outline-light fw-bold"
              >
                <i className="bi bi-envelope-at"></i>&nbsp; Me Contacter Pour un
                Projet
              </a>
            </div>
            <div className="col-lg-4 text-center stats">
              <div>
                <i className="bi bi-globe2"></i>
              </div>
              <small>Expérience Internationale</small>
              <hr />
              <div>
                <i className="bi bi-4-square"></i>
              </div>
              <small>Années d'Expérience</small>
              <hr />
              <div>
                <i className="bi bi-2-square"></i>&nbsp;
                <i className="bi bi-0-square"></i>
              </div>
              <small>Projets</small>
            </div>
            <div className="col-lg-4 text-center">
              <img
                className="shadow rounded profil"
                src={profil}
                alt="Salia Camara"
              />
            </div>
          </div>
        </div>
      </div>
      <img
        className="divider dividerBottomRelative"
        src={wavesDivider}
        alt="Divider"
      />
    </>
  );
};

export default Home;
