import React from "react";
import { createPortal } from "react-dom";
import styles from "./navbar.module.css";
import logo from "../assets/logo.png";
const Navbar = () => {
  return (
    <>
      {createPortal(
        <nav
          className={`${styles.navbar} navbar navbar-expand-lg bg-body-tertiary fixed-top shadow`}
        >
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img className={styles.logo} src={logo} alt="Salia Camara Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="bi bi-three-dots-vertical"></i>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#home"
                  >
                    <i className="bi bi-house"></i>&nbsp;Accueil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#testimonials">
                    <i className="bi bi-stars"></i>&nbsp;Témoignages
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#projects">
                    <i className="bi bi-boxes"></i>&nbsp;Projets
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#skills">
                    <i className="bi bi-body-text"></i>&nbsp;Compétences
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-translate"></i>&nbsp;Languages
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="#contacts">
                    <i className="bi bi-envelope-at"></i>&nbsp;Contacts
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>,
        document.getElementById("modals")
      )}
    </>
  );
};

export default Navbar;
